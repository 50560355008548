import React from 'react'

//Internal Imports
import ContactLeftForm from './ContactLeftForm';
import ContactRightForm from './ContactRightForm';

//Styled Imports and Css Imports
import * as CFStyle from '../../styles/websiteLandingPage/websiteContactPage';


const ContactForm = () => {
    return (
        <CFStyle.ContactParent>
            
            <CFStyle.ContactParentGrid container spacing={ 2 }>
                <ContactLeftForm />
                <ContactRightForm />
            </CFStyle.ContactParentGrid>    
        </CFStyle.ContactParent>
    )
}

export default ContactForm;