import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';


import './styles/appStyle.css';


ReactDOM.render(<App/>,document.querySelector("#root")
);
  