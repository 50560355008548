import React from 'react';

import * as CFStyle from '../../styles/websiteLandingPage/websiteContactPage';
import * as HeaderStyle from '../../styles/websiteLandingPage/websiteLandingPage';

const ContactLeftForm = () => {
    return (
        <CFStyle.ContactLeftItem item lg = {6} xs = {12} md = {6}>
            <CFStyle.FormParent>
                <HeaderStyle.WebsiteHeader>
                        <img src = '../../styles/websiteLandingPage/assets/header.png' />
                </HeaderStyle.WebsiteHeader>
                <CFStyle.LeftItemContent>
                    <CFStyle.LeftItemContentParent>
                        <CFStyle.LFTypoMain>
                            Contact our team
                        </CFStyle.LFTypoMain>
                        <CFStyle.LFTypoSub>
                            With our team you can:
                        </CFStyle.LFTypoSub>
                        <CFStyle.LFTypoUL>
                            <CFStyle.LFTypoLI>
                                Genearte direct demand
                            </CFStyle.LFTypoLI>
                            <CFStyle.LFTypoLI>
                                Save OTA commisions
                            </CFStyle.LFTypoLI>
                            <CFStyle.LFTypoLI>
                                Earn higher profits
                            </CFStyle.LFTypoLI>
                        </CFStyle.LFTypoUL>
                    </CFStyle.LeftItemContentParent>
                    <CFStyle.LeftItemContentImage>

                    </CFStyle.LeftItemContentImage>
                </CFStyle.LeftItemContent>
            </CFStyle.FormParent>
        </CFStyle.ContactLeftItem>
        
    )
}

export default ContactLeftForm;