import React, { useState } from 'react';
const yup = require('yup');

//Internal imports
import API from '../../api';
import { MOBILE_REGEX } from '../../config/constants';


//Material UI and styled Imports.
import * as CFStyle from '../../styles/websiteLandingPage/websiteContactPage';

const ContactRightForm = () => {

    
    const [formState, setFormState] = useState({});
    const [errorObj, setErrorObj] = useState({});

    const focusOutHandler = (event) => {
        setFormState((prevObj) => ({
            ...prevObj,
            [event.target.name]:event.target.value
        }));
    }

    const yupObject = yup.object().shape({
        fullname:yup.string().required(),
        mobile:yup.string().required().matches(MOBILE_REGEX,'Phone number is not valid'),
        city:yup.string().required(),
        email:yup.string().email().required()
    })

    const handleClick = async (event) => {
        // yupObject.validate(formState, {abortEarly: false}).then((formData) => {

        // }).catch(err => {

        // })
        const validated = await yupObject.isValid(formState, { abortEarly:false});
        if(validated) {
            const resp = await API.post('saveHotelInquiryForm',{
                name:formState.fullname,
                mobile_number:formState.mobile,
                city:formState.city,
                email_id:formState.email,
                remarks:formState.remarks
            })
        }
        else {
            yupObject.validate(formState,{ abortEarly:false}).catch((err) => {
                const errors = err.inner.reduce((acc, error) => {
                    return {
                      ...acc,
                      [error.path]: true,
                    }
                  }, {});
                  setErrorObj((prevObj) => ({
                    ...errors
                  }))
            })
        }
    }

    const checkIfError = (name) => {
        if(errorObj[name]) {
            return {
                error:true
            }
        }
    }

    return (
        <CFStyle.ContactRightItem item lg = {6} xs = {12} md = {6}>
            <CFStyle.FormParentRight>
                <CFStyle.FormRow>
                    <CFStyle.FormLabel>
                        Full Name
                    </CFStyle.FormLabel>
                    <CFStyle.FormRowTextField { ...checkIfError('fullname') } size = "small" onChange={focusOutHandler} name = 'fullname'>

                    </CFStyle.FormRowTextField>
                </CFStyle.FormRow>
                <CFStyle.FormRow>
                    <CFStyle.FormLabel>
                        Email
                    </CFStyle.FormLabel>
                    <CFStyle.FormRowTextField { ...checkIfError('email') } type = 'email' size = "small" onChange={focusOutHandler} name = 'email'  value = { formState["email"] || '' }>
                        
                    </CFStyle.FormRowTextField>
                </CFStyle.FormRow>
                <CFStyle.FormRow>
                    <CFStyle.FormLabel>
                        Phone Number
                    </CFStyle.FormLabel>
                    <CFStyle.FormRowTextField { ...checkIfError('mobile') } size = "small" onChange={focusOutHandler} name= 'mobile' value = { formState["mobile"] || '' }>
                        
                    </CFStyle.FormRowTextField>
                </CFStyle.FormRow>
                <CFStyle.FormRow>
                    <CFStyle.FormLabel>
                        City                        
                    </CFStyle.FormLabel>
                    <CFStyle.FormRowTextField { ...checkIfError('city') } value = { formState["city"] || '' } name='city' size = "small" InputProps={{ border:'1px solid #A6BCDA' }} onChange={focusOutHandler}>
                        
                    </CFStyle.FormRowTextField>
                </CFStyle.FormRow>
                <CFStyle.FormRow>
                    <CFStyle.FormLabel>
                        Anything else ?
                    </CFStyle.FormLabel>
                    <CFStyle.FormRowTextField name= 'remarks' multiline onChange={focusOutHandler} value = { formState["remarks"] || '' }>
                        
                    </CFStyle.FormRowTextField>
                </CFStyle.FormRow>
                <CFStyle.FormRow>
                    <CFStyle.FormSubmit onClick = { handleClick } rows={3} size="small" variant="contained">  Submit </CFStyle.FormSubmit>
                </CFStyle.FormRow>
            </CFStyle.FormParentRight>
        </CFStyle.ContactRightItem>
    )
}

export default ContactRightForm;