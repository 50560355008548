import React from 'react';

import * as FooterStyle from '../../styles/websiteLandingPage/websiteLandingPage';
import * as HWLandingPage from '../../styles/websiteLandingPage/websiteLandingPage';

const HotelioFooter = () => {

    const openMail = () => {
        window.open('https://mail.google.com/mail/?view=cm&fs=1&to=hello@hotelio.in','_blank');
    }

    const callMobile = () => {
        window.open('tel:+919971044457','_blank');
    }
    return (
        <FooterStyle.FooterSection>
            <FooterStyle.FooterGridContainer container spacing={2}>
                <FooterStyle.FooterGridItem item xs = {12} sm = {6}>
                    {/* <FooterStyle.LGridItemHeader>
                        HOTELIO
                    </FooterStyle.LGridItemHeader> */}
                    <HWLandingPage.WebsiteHeader>
                        {/* <img src = '../../styles/websiteLandingPage/assets/4.png' /> */}
                        <img src = '../../styles/websiteLandingPage/assets/blacklogo.png' />
                    </HWLandingPage.WebsiteHeader>
                    <FooterStyle.Body>
                        {/* <FooterStyle.BodyText>
                            Hotelio is a new-age travel technology company enabling market access for hoteliers and help improve profitablity. */}
                             {/* <br/>
                            along with providing solutions for business growth, streamline operations <br/>and enhance guest experience. */}
                        {/* </FooterStyle.BodyText> */}
                        <FooterStyle.FollowContainer>
                            <FooterStyle.FollowText>Follow us:</FooterStyle.FollowText>
                            <FooterStyle.FollowIcons>
                                <FooterStyle.FBIcon onClick = { () => window.open('https://www.facebook.com/profile.php?id=100094717363031','_blank')}></FooterStyle.FBIcon>
                                <FooterStyle.TwIcon onClick = { () => window.open('https://twitter.com/HotelioHQ','_blank')}></FooterStyle.TwIcon>
                                <FooterStyle.InstaIcon onClick = { () => window.open('https://www.instagram.com/hoteliohq/','_blank')}></FooterStyle.InstaIcon>
                                <FooterStyle.LIcon onClick = { () => window.open('https://www.linkedin.com/company/hoteliohq','_blank')}></FooterStyle.LIcon>
                                <FooterStyle.Youtube onClick = { () => window.open('https://www.youtube.com/@Hotelio','_blank')}></FooterStyle.Youtube>
                            </FooterStyle.FollowIcons>
                        </FooterStyle.FollowContainer>
                    </FooterStyle.Body>
                    <FooterStyle.Footer>
                        <FooterStyle.CopyRight>
                            Copyright 2023. All Rights Reserved by hotelio
                        </FooterStyle.CopyRight>                    
                    </FooterStyle.Footer>
                </FooterStyle.FooterGridItem>
                <FooterStyle.FooterGridItem item xs = {12} sm = {6}>
                    <FooterStyle.RGridItemHeader>
                        Contact Info
                    </FooterStyle.RGridItemHeader>
                    <FooterStyle.RBody container spacing={2}>
                        {/* <FooterStyle.FAddressBody item xs = {12} sm = {4}>
                            <FooterStyle.IconParent>
                                <FooterStyle.IconImg src = '../../styles/websiteLandingPage/assets/Address.svg' />
                                
                            </FooterStyle.IconParent>
                            <FooterStyle.FATextParent>
                                <FooterStyle.FATextHeader>
                                    Address
                                </FooterStyle.FATextHeader>
                                <FooterStyle.FATextSubHeader>
                                    WeWork Forum, DLF Cyber City,<br/> Gurugram, Haryana 122002
                                </FooterStyle.FATextSubHeader>
                            </FooterStyle.FATextParent>
                        </FooterStyle.FAddressBody> */}
                        <FooterStyle.FAddressBody item xs = {12} sm = {6}>
                            <FooterStyle.IconParent>
                                <FooterStyle.IconImg src = '../../styles/websiteLandingPage/assets/email.svg' />
                            </FooterStyle.IconParent>
                            <FooterStyle.FATextParent>
                                <FooterStyle.FATextHeader>
                                    Email
                                </FooterStyle.FATextHeader>
                                <FooterStyle.FATextSubHeader onClick = { openMail }>
                                    hello@hotelio.in
                                </FooterStyle.FATextSubHeader>
                            </FooterStyle.FATextParent>
                        </FooterStyle.FAddressBody>
                        <FooterStyle.FAddressBody item xs = {12} sm = {6}>
                            <FooterStyle.IconParent>
                                <FooterStyle.IconImg src = '../../styles/websiteLandingPage/assets/phone.svg' />
                                {/* <FooterStyle.FPIcon>

                                </FooterStyle.FPIcon> */}
                            </FooterStyle.IconParent>
                            <FooterStyle.FATextParent>
                                <FooterStyle.FATextHeader>
                                    Phone
                                </FooterStyle.FATextHeader>
                                <FooterStyle.FATextSubHeader onClick = { callMobile }>
                                    +91 99710 44457
                                </FooterStyle.FATextSubHeader>
                            </FooterStyle.FATextParent>
                        </FooterStyle.FAddressBody>
                    </FooterStyle.RBody>
                    {/* <FooterStyle.Footer>
                        <FooterStyle.FooterRight>
                            <FooterStyle.MiscFooter>
                                Terms
                            </FooterStyle.MiscFooter>
                            <FooterStyle.MiscFooter>
                                Privacy
                            </FooterStyle.MiscFooter>
                            <FooterStyle.MiscFooter>
                                Support
                            </FooterStyle.MiscFooter>
                        </FooterStyle.FooterRight>                    
                    </FooterStyle.Footer> */}
                </FooterStyle.FooterGridItem>
            </FooterStyle.FooterGridContainer>
        </FooterStyle.FooterSection>
    )
}

export default HotelioFooter