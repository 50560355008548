import React from 'react';
import { useHistory } from 'react-router';

//Styled Imports
import * as HWLandingPage from '../../styles/websiteLandingPage/websiteLandingPage';
// import LargeImg from '../../styles/websiteLandingPage/assets/large.svg'

//Internal Imports
import SecondSection from './SecondGridContainer';
import WhatsApp from './whatsApp';
import ReviewContainer from './ReviewContainer';
import LastContainer from './LastContainer';
import HotelioFooter from './HotelioFooter';
import ThirdGridContainer from './ThirdGridContainer';
import HotelNewFirstSection from './HotelNewFirstSection';

const HotelioWebsiteLanding = () => {

    const history  = useHistory();
    return (
        <HWLandingPage.WebsiteContainer>
            {/* <HWLandingPage.FirstSection>
                <HWLandingPage.FirstSectionContent>
                        <HWLandingPage.WebsiteHeader>
                                <img src = '../../styles/websiteLandingPage/assets/4.png' />
                        </HWLandingPage.WebsiteHeader>
                    <HWLandingPage.FirstGridContainer container spacing={2}>
                        <HWLandingPage.FirstChild item xs = {12} sm = {8} lg = {6}>
                            <HWLandingPage.FGText>
                                Increase your hotel
                                <br></br>
                                Occupancy by 30%
                            </HWLandingPage.FGText>
                            <HWLandingPage.SubHeaderText>
                                Generate direct demand, save OTA commisions and 
                                <br/> 
                                earn higher profit
                            </HWLandingPage.SubHeaderText>
                            <HWLandingPage.LaunchText>
                                Launch your hotel website in 10 minutes
                            </HWLandingPage.LaunchText>
                            <HWLandingPage.ButtonContainer>
                                <HWLandingPage.StartNowContainer>
                                    <HWLandingPage.StartNowButton onClick = { () => window.open('https://app.hotelio.in','_blank')} variant = 'contained'>
                                        Start Now
                                    </HWLandingPage.StartNowButton>
                                    <HWLandingPage.FreeTrialText>
                                        3 Months Free Trial
                                     </HWLandingPage.FreeTrialText>
                                </HWLandingPage.StartNowContainer>
                                <HWLandingPage.ContactUsButton variant='contained' onClick = {() => { window.open('https://forms.gle/DtHCnBBT12LtHA3q8'),'_blank' }}>
                                    Contact Us
                                </HWLandingPage.ContactUsButton>
                            </HWLandingPage.ButtonContainer>
                           
                        </HWLandingPage.FirstChild>
                        <HWLandingPage.SecondChild item xs = {12} sm = {8} lg = {6}>
                            <HWLandingPage.BlurFilter>             
                            </HWLandingPage.BlurFilter>
                            <HWLandingPage.LargeImage>
                            </HWLandingPage.LargeImage>
                        </HWLandingPage.SecondChild>
                    </HWLandingPage.FirstGridContainer>
                </HWLandingPage.FirstSectionContent>
            </HWLandingPage.FirstSection> */}
            {/* <ThirdGridContainer /> */}
            {/* <SecondSection /> */}
            <HotelNewFirstSection />
            {/* <ReviewContainer />
            <LastContainer /> */}
            <HotelioFooter />
            <WhatsApp />
        </HWLandingPage.WebsiteContainer>
    )
}

export default HotelioWebsiteLanding;