import React from 'react';

//MUI and Css Imports.
import * as HWLandingPage from '../../styles/websiteLandingPage/websiteLandingPage';

const HotelNewFirstSection = () => {

    const hotelClickHandler = () => {
        window.open('https://app.hotelio.in','_blank');
    }

    return (
        <HWLandingPage.NFPContainer>
            <HWLandingPage.NFPLeftSection>
                <HWLandingPage.HotelioHeading>
                    {/* Hotelio */}
                    {/* <img src = '../../styles/websiteLandingPage/assets/blacklogo.png' /> */}
                </HWLandingPage.HotelioHeading>
                <HWLandingPage.Title1>
                    <HWLandingPage.Title1Normal>
                        Boost Your Hotel <br/>
                    </HWLandingPage.Title1Normal>
                    <HWLandingPage.Title1Color>
                        Business Today.
                    </HWLandingPage.Title1Color>
                </HWLandingPage.Title1>
                <HWLandingPage.Title2>
                    Create a stunning hotel page, attract guests, and boost bookings.Elevate your property's online presence and host unforgettable stays with ease.
                </HWLandingPage.Title2>
                <HWLandingPage.CreateHotelButton size="small" variant="contained" onClick={hotelClickHandler}> 
                    Create Your Hotel
                </HWLandingPage.CreateHotelButton>
            </HWLandingPage.NFPLeftSection>
            <HWLandingPage.NFPRightSection>
                <img src = '../../styles/websiteLandingPage/assets/landingpagenew.png' /> 
            </HWLandingPage.NFPRightSection>
        </HWLandingPage.NFPContainer>
    )
}

export default HotelNewFirstSection;