import React from 'react';

//Material UI and Styled Imports
import * as ChatStyle from '../../styles/websiteLandingPage/websiteLandingPage';
import useMediaQuery from '@mui/material/useMediaQuery';

const WhatsApp = () => {
    const matches = useMediaQuery('(max-width:576px)');
    // const contactDetails = useSelector(state => state.bookingDetails.contactDetails);

    // let encodeContactNo;
    // if(contactDetails)
    //     encodeContactNo = contactDetails.find(contact => contact.contact_type == 'Phone').contact_value;

    const clickHandler = () => {
        // window.open(`https://api.whatsapp.com/send?phone=${encodeURIComponent(+919971044457)}`);
        if(matches)
            window.open(`https://api.whatsapp.com/send?phone=+919971044457&text=Hello!`);
        else 
            window.open(`https://api.whatsapp.com/send?phone=9971044457&text=Hello!`);
        //window.open('https://wa.me/+919599110691')
    }

    return (
        <ChatStyle.WhatsAppBox onClick = { clickHandler }>
            <ChatStyle.WhatsAPPICON></ChatStyle.WhatsAPPICON>
        </ChatStyle.WhatsAppBox>
    )
}

export default WhatsApp;