//External Imports
import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

//Material UI Imports
import * as AppStyle from '../styles/appStyle.js';

//Interal Imports
import HotelioWebsiteLanding from './HotelioWebsite/HotelioWebsiteLanding.js';
import ContactForm from './Contact';

const App = () => {
    return (
        <Router>
            <AppStyle.AppBox>
                <Switch>
                    <Route path = '/' exact component = {HotelioWebsiteLanding} />
                </Switch>
                <Switch>
                    <Route path = '/Contact' component = {ContactForm} />
                </Switch>
            </AppStyle.AppBox>
        </Router>
    )
}

export default App;