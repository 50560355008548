import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';


export const ContactParentGrid = styled(Grid)`
&& {
    flex-grow:1;
    background-color: #1C60C2;
}`

export const ContactLeftItem = styled(Grid)`
&& {
    padding:2rem 4rem;
    @media (max-width: 576px) {
        display:none;
    }
}`

export const ContactRightItem = styled(Grid)`
&& {
    background-color:white;
    padding:2rem 4rem;
    @media (max-width: 576px) {
        padding:0rem 2rem 0rem 3rem !important;
    }
}`

export const ContactParent = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    height:100vh;
    width:100vw;
}`

export const LeftItemContent = styled(Box)`
&& {
    flex-grow:1;
    display:flex;
    flex-direction:column;
}`

export const FormParent = styled(Box)`
&& {
    padding:2rem 0 2rem 4rem;
    height:100%;
    display:flex;
    flex-direction:column;
}`

export const FormParentRight = styled(Box)`
&& {
    padding:3rem 4rem 2rem 2rem;
    height:100%;
    display:flex;
    flex-direction:column;
    @media (max-width: 576px) {
        padding:3rem 2rem 2rem 3rem;
    }
}`

export const LeftItemContentParent = styled(Box)`
&& {
    height:50%;
    padding: 1rem;
    display:flex;
    justify-content:center;
    flex-direction:column;
}`

export const LeftItemContentImage = styled(Box)`
&& {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    height:50%;
    background-image:url('styles/websiteLandingPage/assets/contactus.png')
}`

export const LFTypoMain = styled(Typography)`
&& {
    font-size:3.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color:white;
    margin-bottom:0.5rem;
}`

export const LFTypoSub = styled(Typography)`
&& {
    font-size:1.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color:white;
}`

export const LFTypoUL = styled.ul`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color:white;
    margin:0.5rem 0;
}`

export const LFTypoLI = styled.li`
&& {
    font-size:1.25rem;
    margin-top:0.6rem;
}`

export const FormRow = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    margin-bottom:2rem;
}`

export const FormLabel = styled(Typography)`
&& {
    font-size:0.82rem;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color:#354665;
    margin-bottom:0.5rem;
}`

export const FormRowTextField = styled(TextField)`
&& {
   
}`

export const FormSubmit = styled(Button)`
&& {
    height:2.5rem;
    display:flex;
    background-color:#0A58CA;
    color:white;
    width: fit-content;
    padding: 0 2rem;
    font-size:1rem;
}`
