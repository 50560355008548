import styled from "styled-components";

import Box from '@mui/material/Box';

export const AppBox = styled(Box)`
&& {
    display:flex;
    font-family:Roboto;
    flex-direction:column;
    width: 100vw;
    background-color:white;
    box-sizing:border-box;
}`

