import styled from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, Button } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Rating from '@mui/material/Rating';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const WebsiteContainer = styled(Box)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    display:flex;
    flex-direction:column;
    height:100vh;
    background-color:#F6F6F6;
}`

export const FirstGridContainer = styled(Grid)`
&& {
    position:relative;
    color:var(--fgfont);
    display:flex;
    justify-content:flex-start;
    flex-grow:1;
    align-items:center;
    padding-top: 2rem;
}`

export const WebsiteHeader = styled(Typography)`
&& {
    top:1rem;
    font-size:2.5rem;
    height:60px;
    & img {
        height: 100%;
        background-position-x: 0;
        position: relative;
        left: -17px;
    }
}`
// @media (max-width: 576px) {
//background: linear-gradient(80.88deg, #1C5FC3 8.4%, #0846A2 99.26%);
export const FirstSection = styled(Box)`
&& {
    height:85vh;
    position:relative;
    width:100vw;
    background-image: url(styles/websiteLandingPage/assets/landingpage.svg);
    @media only screen {
        height:auto;
    };
    @media all and (orientation:landscape) {
        height:auto;
    }
}`


export const FirstSectionImage = styled.img`
&& {
    height:100%;
    width:100vw;
}`

export const FirstSectionContent = styled(Box)`
&& {
    padding: 1rem 4rem 3rem 4rem;
    z-index:2;
    background-color:transparent;
    height:100%;
    width:100vw;
    display:flex;
    flex-direction:column;
    @media (max-width: 576px) {
        padding: 1rem 2rem 3rem 2rem;
    }
}`

export const FGText = styled(Typography)`
&& {
    color:var(--fgfont);
    font-size:3.75rem;
    font-weight:700;
    font-family: 'Poppins';
    font-style: normal;
    line-height:4.5rem;
    @media (max-width: 576px) {
        font-size:2.5rem;
    }
   
}`

export const FirstChild = styled(Grid)`
&& {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}`

export const SecondChild = styled(Grid)`
&& {
    position:relative;
    height:100%;
    background-repeat: no-repeat;
    background-size: 100%;
    @media (max-width: 900px) {
        display:none;
    }
}`

// background: linear-gradient(48.44deg, #3EC594 42.28%, #3EC0C5 66.01%, rgba(62, 192, 197, 0) 85.4%);
//filter: blur(102.611px);
export const BlurFilter = styled(Box)`
&& {
    height:100%;
    width:100%;
    position:absolute;
    z-index:1;
   
}`

export const SubHeaderText = styled(Typography)`
&& {
    font-size:1.12rem;
    font-family: 'Poppins';
    font-weight:500;
    font-style: normal;
    margin-top:1rem;
    line-height:27px;
    @media (max-width: 576px) {
        font-size:1rem;
    }
}`

export const LaunchText = styled(Typography)`
&& {
    font-size:1.12rem;
    font-family: 'Poppins';
    font-weight:500;
    font-style: normal;
    margin-top:1rem;
    line-height:27px;
}`

export const ButtonContainer = styled(Box)`
&& {
    padding-top:1.5rem;
    display:flex;
}`

export const StartNowContainer = styled(Box)`
&& {
    margin-right: 2rem;
}`

export const StartNowButton = styled(Button)`
&& {
    background-color: white;
    color: #0C4BA9;
    font-family: 'Roboto';
    font-style: normal;
    border-radius: 1.5rem;
    font-weight:bold;
    &:hover {
        background-color: white;
        color: #0C4BA9;
    };
    width: 13.5rem;
    height:3rem;
    font-size:1rem;
}`

export const ContactUsButton = styled(Button)`
&& {
    border-radius: 1.5rem;
    border: 1px solid #FFFFFF;
    font-weight:bold;
    font-family: 'Roboto';
    font-size:1rem;
    padding: 4px 0;
    color:white;
    &:hover {
        color:white;
    };
    width: 13.5rem;
    height:3rem;
    font-size:1rem;
    background-color:#1155A8;
}`

export const FreeTrialText = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    font-size: 0.9rem;
    display:flex;
    justify-content:center;
    margin-top: 0.5rem;
    cursor:pointer;
}`

export const LargeImage = styled(Box)`
&& {
    height: 100%;
    width: 100%;
    box-sizing:border-box;
    display: flex;
    justify-content: center;
    z-index: 2;
    position: relative;
    top:0px;
    align-items: center;
    background-size: contain;
    padding:2rem;
    
}`
// background-repeat:no-repeat;
//     background-image: url(styles/websiteLandingPage/assets/mainmobile.png);
export const SmallImage = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    z-index: 3;
    position: absolute;
    align-items: center;
    right: -10%;
    top: 50%;
    transform: translateY(-43%);
}`

export const ExtraBackground = styled(Box)`
&& {
    height: 10rem;
    width: 10rem;
    position: absolute;
    right: -14%;
    border-radius: 50%;
    top: 8%;
    z-index:1;
}`

/* Second Grid Container */

export const SecondSection = styled(Box)`
&& {
    background-color:#FFFFFF;
}`

export const SGHeaderContainer = styled(Box)`
&& {
    padding:0 4rem 0rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 576px) {
        padding:0 2rem 0rem 2rem;
    };
}`
export const SGHeader = styled(Typography)`
&& {    
    color:#031633;
    font-size:2rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    @media (max-width: 576px) {
        font-size:1.8rem;
    };
}`

export const SGSubHeader = styled(Typography)`
&& {
    color:#343A40;
    font-size:1.12rem;
    opacity:0.9;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    @media (max-width: 576px) {
        font-size:1rem;
    };
}`

export const SGBody = styled(Box)`
&& {
    padding:3rem 4rem 2rem 4rem;
    @media (max-width: 576px) {
        padding:3rem 0rem 2rem 1rem;
    };
}`

export const SGGridContainer = styled(Grid)`
&& {

}`

export const SGGridItem = styled(Grid)`
&& {
    display:flex;
    justify-content;
    padding-right:2rem !important;
    padding-left:2rem !important;
}`

export const SGGridBox = styled(Box)`
&& {
    background: #FFFFFF;
    border: 1px solid #E9ECEF;
    border-radius: 10px;
    width:100%;
    padding:1rem 1rem 2rem 1rem;
}`

export const ItemIcon = styled(Box)`
&& {
    width:50px;
    height:50px;
    & img {
        height:50px;
        width:50px;
    }
}`

export const ItemHeader = styled(Box)`
&& {
    margin-top:1rem;
    font-size:1.25rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    color:var(--fontColor);
}`

export const ItemText = styled(Box)`
&& {
    margin-top:1rem;
    font-family: 'Poppins';
    ont-style: normal;
    font-weight: 400;
    font-size:1.12rem;
    color:#343A40;
    opacity:0.9;
}`

export const ThirdSection = styled(Box)`
&& {
    display:flex;
    justify-content:center;
    height:240px;
    border-radius: 10px;
    align-items:center;
    margin-bottom:3rem;
    background-repeat: no-repeat;
    background-size:contain;
    background-image: url(styles/websiteLandingPage/assets/performance.png);
    margin: 2rem 4rem 2rem 4rem;
    @media (max-width: 576px) {
        border-radius: 0px;
        height: 90px;
        background-size: cover;
        width: 99%;
        margin: 2rem 0 3rem 0rem;
        background-image: url(styles/websiteLandingPage/assets/performancemobile.png);
    };
}`


export const TGBox = styled(Box)`
&& {
    width:25%;
    display:flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
}`

export const TGHeader = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size:1.5rem;
    color:#FAFAFA;
    @media (max-width: 576px) {
        font-size:0.8rem;
    }
}`

export const TGText = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size:1rem;
    color:#FAFAFA;
    @media (max-width: 576px) {
        font-size:0.65rem;
    }
}`

export const FourthSection = styled(Box)`
&& {
    background-color:#F0F6FF;
    padding:2rem 4rem 5rem 4rem;
    @media (max-width: 576px) {
        padding:2rem 2rem 5rem 2rem;
    };
}`

export const HeartIcons = styled(FavoriteIcon)`
&& {
    margin-right:0.5rem;
    color:#CF1322;
    font-size:2rem;
    margin-top: 0.5rem;
}`

export const FSHeaderBox = styled(Box)`
&& {
 
}`

export const FSHeaderText = styled(Box)`
&& {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}`

export const FSHeaderTypo = styled(Typography)`
&& {
    margin-right:0.5rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size:2rem;
}`

export const  SeparatorContainer = styled(Box)`
&& {
    display: flex;
    justify-content: center;
}`

export const FirstSeparator = styled(Box)`
&& {
    height:3px;
    width:10px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0A58CA;
    margin-right:4px;
}`

export const SecondSeparator = styled(Box)`
&& {
    height:3px;
    width:50px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #0A58CA;
    margin-right:2px;
}`

export const RCBody = styled(Box)`
&& {
    padding-top: 1.5rem;
}`

export const RCGridContainer = styled(Grid)`
&& {
    
}`

export const RCGridItem = styled(Grid)`
&& {
    position:relative;
}`

export const RCGridItemBox = styled(Box)`
&& {
    background-color:transparent;
    border-radius: 20px;
    padding:2rem 3.5rem 1rem 1.5rem;
    position:relative;
    z-index:3;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}`

export const ReviewBlurFilter = styled(Box)`
&& {
    height: 10rem;
    width: 10rem;
    position: absolute;
    background: linear-gradient(48.44deg, #3EC594 42.28%, #3EC0C5 66.01%, rgba(62, 192, 197, 0) 85.4%);
    filter: blur(75.611px);
    top: 100%;
    transform: translate(3%, -82%);
    left: 5%;
    z-index: 2;
}`

export const HeaderBodyContainer = styled(Box)`
&& {

}`


// export const ReviewImage = styled.img`
// && {
//     z-index:2;
//     width:100%;
// }`

  //  background-color:#4D4C4C;
export const ReviewImage = styled(Box)`
&& {
    background-size: cover;
    border-radius: 20px;
    z-index: 2;
    width: 100%;
    position: absolute;
    height: 100%;
    background-image: url(styles/websiteLandingPage/assets/testimonials.svg);
    background-repeat:no-repeat;
}`

export const GridItemContainer = styled(Box)`
&& {
    position:relative;
    height:100%;
}
`

export const ReviewHeader = styled(Box)`
&& {
    display:flex;
}`

export const ReviewHeaderIcons =styled(PersonPinIcon)`
&& {
    height:70px;
    width:70px;
    color:white;
}`

export const ReviewHeaderText = styled(Box)`
&& {
    margin-left: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}`

export const ReviewHeaderTypo = styled(Typography)`
&& {
    color:var(--fgfont);
    font-size:1.25rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.03rem;
}`

export const ReviewSubHeaderTypo = styled(Typography)`
&& {
    color:var(--fgfont);
    font-size:1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.03rem;
}`

export const ReviewBody = styled(Typography)`
&& {
    color:var(--fgfont);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.12rem;
    margin: 1rem 0px;
    line-break: auto;
}`

export const ReviewFooter = styled(Box)`
&& {
    padding-top:0.5rem;
    display:flex;
    justify-content: space-between;
}`

export const ReviewFooterText = styled(Typography)`
&& {
    color:var(--fgfont);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
}`

export const ReviewRating = styled(Rating)`
&& {
    display: flex;

}`

export const LastSection = styled(Box)`
&& {
    padding:3rem 4rem;
    background-color:white;
    position:relative;
    @media (max-width: 576px) {
        padding:3rem 2rem;
    };
}`

export const LastSectionImg = styled.img`
&& {
    width:100%;
    height:230px;
    position:relative;
    z-index:1;
}`

export const LastSectionCorner = styled.img`
&& {
    z-index:2;
    position:absolute;
    top:4rem;
    right:4rem;
}`

    /* background: rgba(14, 39, 75, 0.89);
    box-shadow: 1px 3px 8px rgba(50, 50, 71, 0.17);*/

    // background-size: 100%;

//    background-image:url(styles/websiteLandingPage/assets/freetrial.png);    
export const LastSectionBody = styled(Box)`
&& {
    background-color:#F0F6FF !important;
    box-sizing:border-box;
    background-color:transparent;
    width:100%;
    height:240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    z-index:3;
    padding:2.5rem 0;
    background-repeat:no-repeat;
    border-radius:10px;
    background-size:cover;

    @media (max-width: 576px) {
        background-image: url(styles/websiteLandingPage/assets/freetrialmobile.png);
        background-size: cover;
        height: 200px;
    };
}`


export const LastSectionTypo = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    color:#002766;
    display:flex;
    justify-content:center;
    text-align:center;
    padding:0 1rem;
    @media (max-width: 576px) {
        font-size: 1.5rem;
    };
}`

export const LastSectionSubTypo = styled(Typography)`
&& {
    padding:0 4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color:#002766;
    opacity:0.9;
    display:flex;
    justify-content:center;
    text-align:center;
    padding:0 1rem;
}`

 // margin-right: 2rem;
    // padding-left: 2rem;
    // padding-right: 2rem;
export const LCStartNowButton = styled(Button)`
&& {
    background-color: #0C4AA7;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight:bold;
    display:flex;
    justify-content:center;
    &:hover {
        background-color: #0C4AA7;
        color: white;
    };
    border-radius: 25px;
    gap: 10px;
    width:11rem;
    height:3rem;
    margin-top:0.5rem;

}`

export const FooterSection = styled(Box)`
&& {
    border-top:1px solid #040C34;
    background-color: #F6F6F6;
    padding:0.5rem 0rem 1rem 0rem;
    margin-left:11rem;
    margin-right:11rem;
    @media (max-width: 576px) {
        padding:2rem 2rem 1.5rem 2rem;
         margin-left:0rem;
        margin-right:0rem;
    };
}`

export const FooterGridContainer = styled(Grid)`
&& {

}`

export const FooterGridItem = styled(Grid)`
&& {
    display:flex;
    flex-direction:column;
}`

export const LGridItemHeader = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size:1.8rem;
    color:var(--fgfont);
    display:flex;
    height:40px;
    flex-direction:column;
    justify-content:center;
}`

export const RGridItemHeader = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size:1.12rem;
    color:var(--fgfont);
    display:flex;
    height:40px;
    flex-direction:column;
    justify-content:center;
}`

export const Body = styled(Box)`
&& {
    display:flex;
    flex-direction:column;
    padding:0rem 0;
}`

export const RBody = styled(Grid)`
&& {
    display:flex;
    justify-content:flex-start;
    padding:5px 0;
    flex-grow:1;
}`



export const BodyText = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color:var(--fgfont);
    opacity: 0.7;
    line-height:normal;
}`

export const FollowContainer = styled(Box)`
&& {
    display:flex;
    padding-top:0rem;
}`

export const FollowText = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color:var(--fgfont);
    opacity: 0.7;
    line-height: 22px;
}`

export const CopyRight = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color:var(--fgfont);
    opacity: 0.7;
}`

export const Footer = styled(Box)`
&& {

}`

export const FollowIcons = styled(Box)`
&& {
    & svg {
        color:black;
        margin-left:0.5rem;
        cursor:pointer;
    }
}`

export const FooterRight = styled(Box)`
&& {
    display:flex;
    justify-content:flex-end;
    @media (max-width: 576px) {
        justify-content:flex-start;
    };
}`

export const MiscFooter = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color:var(--fgfont);
    opacity: 0.7;
    margin-right:0.5rem;
    cursor:pointer;
}`

export const FAddressBody = styled(Grid)`
&& {
    display:flex;
}`

export const FAIcon = styled(HomeOutlinedIcon)`
&& {
    font-size: 1.8rem;
    color: white;
}`

export const FEIcon = styled(EmailOutlinedIcon)`
&& {
    font-size: 1.8rem;
    color: white;
    z-index:3;
}`

export const FPIcon = styled(LocalPhoneOutlinedIcon)`
&& {
    font-size: 1.8rem;
    color: white;
}`

export const FATextParent = styled(Box)`
&& {

}`

export const FATextHeader = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
}`

export const FATextSubHeader = styled(Typography)`
&& {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    opacity:0.7;
    cursor:pointer;
}`

// background: rgba(255, 255, 255, 0.12);
export const IconParent = styled(Box)`
&& {
    height:38px;
    width:38px;
    border-radius:50%;
    background:black;
    border: 1px dashed rgba(85, 88, 131, 0.53);
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:0.8rem;
    position:relative;
}`

export const IconImg = styled.img`
&& {
    z-index:1;
    position:relative;
}`

export const FBIcon = styled(FacebookIcon)`
&& {
}`

export const TwIcon = styled(TwitterIcon)`
&& {

}`

export const InstaIcon = styled(InstagramIcon)`
&& {

}`

export const LIcon = styled(LinkedInIcon)`
&& {

}`

export const Youtube = styled(YouTubeIcon)`
&& {

}`


export const WhatsAppBox = styled(Box)`
&& {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: white;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: space-around;
    cursor:pointer;
    align-items: center;
    border-radius: 50%;
    background: rgb(14,187,95);
    background: linear-gradient(180deg, rgba(14,187,95,1) 0%, rgba(7,165,80,1) 53%);
    z-index:2;
    @media (max-width: 576px) {
        width:50px;
        height:50px;
    }
}`

export const WhatsAppBoxContent = styled(Typography)`
&& {
    font-family:'Red Hat Display';
    font-size:0.8rem;
    text-transform:uppercase;
}`

export const WhatsAPPICON = styled(WhatsAppIcon)`
&& {
    color:white;
    font-size:2.5rem;
    @media (max-width: 576px) {
        font-size:3rem;
    }
}`


//New First Section Css start
//    background: linear-gradient(180deg, rgba(239,244,249,1) 0%, rgba(253,253,253,1) 100%);
export const NFPContainer = styled(Box)`
&& {
    background: rgb(239,244,249);
    background:#F6F6F6;
    padding:2rem;
    flex-grow:1;
    display:flex;
    @media (max-width: 576px) {
        flex-direction:column;
        height:auto;
    }
}`

export const NFPLeftSection = styled(Box)`
&& {
    height:100%;
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left:9rem;
    @media (max-width: 576px) {
       width:100%;
       padding-left:0;
    }
}`

    // background-image:url(styles/websiteLandingPage/assets/landingpage.png);   
export const NFPRightSection = styled(Box)`
&& {
    height:100%;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 50% 55px; 
    @media (max-width: 576px) {
        width:100%;
        background-size:70%;
        background-position: 50% 15px; 
        display:flex;
        justify-content:center;
        height:auto;
        padding-top:1.5rem;
    };
    & img {
        height:422px;
    }
}`

export const CreateHotelButton = styled(Button)`
&& {
    width:fit-content;
    margin-top:2rem;
    text-transform:capitalize;
    background-color:#333537;
    font-size:1.125rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    padding-top:6px;
    padding-bottom:6px;
    border-radius:8px;
    font-weight:600;
    font-family:Poppins;
    &:hover {
        background-color:#333537;
    };
    @media (max-width: 576px) {
        left:50%;
        transform:translate(-50%, 10px);
    }
}`

export const Title2 = styled(Typography)`
&& {
    margin-top:2rem;
    color:#68696b;
    font-size:1.25rem;
    line-height:30px;
    font-weight:600;
    font-family:Poppins;
    @media (max-width: 1400px) {
        font-size:1.20rem;
    };
    @media (max-width: 1200px) {
        font-size:1.1rem;
    };
    @media (max-width: 576px) {
        text-align:center;
    }
}`

export const Title1 = styled(Box)`
&& {
    margin-top:2rem;
}`

export const Title1Normal = styled(Typography)`
&& {
    color:black;
    font-size:3.5rem;
    line-height:3.5rem;
    font-weight:600;
    font-family:Poppins;
    @media (max-width: 1400px) {
        font-size:3rem;
    };
    @media (max-width: 1200px) {
        font-size:2.5rem;
    };
    @media (max-width: 576px) {
        font-size:2rem;
        text-align:center;
    }
}`

export const Title1Color = styled(Typography)`
&& {
    color:#68696b;
    font-size:3.5rem;
    font-weight:600;
    background: radial-gradient(circle at top left, #099ef1 0%, #6863f8 18.82%, #d84ffa 32.6%, #f058c5 52.83%, #ff4f90 68.03%, #ff6558 87.66%, #ff891f 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family:Poppins;
    @media (max-width: 1400px) {
        font-size:3rem;
    };
    @media (max-width: 1200px) {
        font-size:2.5rem;
    };
    @media (max-width: 576px) {
        font-size:2rem;
        text-align:center;
    }
}`

export const HotelioHeading = styled(Typography)`
&& {
    background-position: -10%;
    background-size: 42%;
    background-repeat: no-repeat;
    font-weight:600;
    height: 75px;
    background-image: url(styles/websiteLandingPage/assets/blacklogo.png);
    @media (max-width: 576px) {
        background-position: 50%;
    }
}`
//New First Section Css end